<template>
  <div class="my-bill-title">
    <span v-if="currentPage === 'unpaidAndUnshipped'">待付款待出貨</span>
    <span v-else-if="currentPage === 'paidAndUnshipped'">已付款待出貨</span>
    <span v-else-if="currentPage === 'unpaidAndShipped'">未付款已出貨</span>
    <span v-else-if="currentPage === 'merged'">被併單</span>
    <span v-else-if="currentPage === 'finished'">已完成帳單</span>
  </div>
  <div class="tw-container">
    <div class="management-datatable">
      <DataTable
        class="p-datatable-sm"
        :scrollHeight="`${scrollHeight}px`"
        :value="billLists"
        dataKey="id"
        :loading="dataTabelLoading"
        :rowHover="true"
        :paginator="true"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows="20"
        :rowsPerPageOptions="[20, 50, 100]"
        :scrollable="true"
        currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
        v-model:filters="searchGlobal"
        filterDisplay="row"
        stateStorage="local"
        stateKey="dt-state-myBill-local"
        sortField="id"
        :sortOrder="-1"
        v-model:selection="selectItems"
        v-model:expandedRows="expandedRows"
        stripedRows
      >
        <template #header>
          <input
            class="form-control mb-3"
            v-model="searchGlobal['global'].value"
            placeholder="表格搜尋: 帳單單號"
          />
          <div>
            <button class="tw-btn tw-btn-success me-2 mb-2" @click="expandAll">
              ＋展開全部</button
            ><button class="tw-btn tw-btn-success mb-2" @click="collapseAll">
              －收合全部
            </button>
          </div>
        </template>
        <!---------- 單頭 ----------->
        <Column :expander="true" style="max-width: 50px" />
        <!-- 帳單 -->
        <Column
          class="fw-bolder"
          field="id"
          header="帳單"
          sortable
          :sortOrder="-1"
          style="min-width: 250px; max-width: 250px"
        >
          <template #body="{ data }">
            <p class="mb-2">
              <template v-if="currentPage === 'merged'">
                <p class="badge bg-success">被併單狀態</p>
              </template>
              <template v-else>
                <p class="badge bg-warning" v-if="data.hierarchy > 0">併單</p>
              </template>
            </p>
            <p>單號: {{ data.id }}</p>
            <p>付款方式: {{ data.paymentType }}</p>

            <p title="帳單建立時間" style="cursor: help">
              {{
                $methods.moment(data.createTime).format("YYYY-MM-DD HH:mm:ss")
              }}
            </p>
          </template>
        </Column>
        <!-- 出貨 -->
        <Column
          class="fw-bolder"
          field=""
          header="出貨"
          style="min-width: 250px; max-width: 250px"
        >
          <template #body="{ data }">
            <p>出貨: {{ data.shippingType }}</p>
            <p>收件: {{ data.recipient }}</p>
            <p>電話: {{ data.recipientPhoneNumber }}</p>
            <p>
              地址:
              <span v-if="data.recipientCity">{{ data.recipientCity }}</span>
              <span v-if="data.recipientArea">{{ data.recipientArea }}</span>
              <span v-if="data.receivingAddress">{{
                data.receivingAddress
              }}</span>
            </p>
            <!-- todo 只顯示兩行，做 modal 檢視全部備註 -->
            <p
              class="can-click pointer overflow-hidden text-secondary"
              style="
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              "
              @click="showModal('checkNote', data.note)"
            >
              備註: {{ data.note }}
            </p>
          </template>
        </Column>
        <!-- 發票 -->
        <Column
          class="fw-bolder"
          field=""
          header="發票"
          style="min-width: 250px; max-width: 250px"
        >
          <template #body="{ data }">
            <p>抬頭: {{ data.receiptTitle }}</p>
            <p>統編: {{ data.receiptBusinessAdminNumber }}</p>
            <p>電話: {{ data.receiptPhoneNumber }}</p>
            <p>
              地址:
              <span v-if="data.receiptCity">{{ data.receiptCity }}</span>
              <span v-if="data.receiptArea">{{ data.receiptArea }}</span>
              <span v-if="data.receiptAddress">{{ data.receiptAddress }}</span>
            </p>
          </template>
        </Column>
        <!-- 付款 -->
        <Column
          class="fw-bolder"
          field="hasPaymentNotification"
          header="付款"
          sortable
          style="min-width: 250px; max-width: 250px"
        >
          <template #body="{ data }">
            <p>
              收款狀態:
              <span
                class="text-warning"
                v-if="data.hasPaymentNotification && !data.paid"
                >款項確認中</span
              >
              <span
                class="text-danger"
                v-else-if="!data.hasPaymentNotification && !data.paid"
                >未收款</span
              >
              <span class="text-success" v-else>已收款</span>
            </p>
            <div class="text-primary" v-if="data.hasPaymentNotification">
              <p>付款方式: 匯款</p>
              <p>付款金額: {{ data.paymentNotification.money }}</p>
              <p>
                銀行代碼:
                {{ data.paymentNotification.userPaymentInfo.bankCode }}
              </p>
              <p>
                帳號末五碼:
                {{ data.paymentNotification.userPaymentInfo.accountNumber }}
              </p>
              <p>付款備註: {{ data.paymentNotification.note }}</p>
              <p title="付款時間" style="cursor: help">
                {{
                  $methods
                    .moment(data.paymentNotification.paymentTime)
                    .format("YYYY-MM-DD HH:mm:ss")
                }}
              </p>
            </div>
          </template>
        </Column>
        <!-- 總計 -->
        <Column
          class="fw-bolder"
          field=""
          header="總計"
          style="min-width: 190px"
        >
          <template #body="{ data }">
            <p>運費: {{ data.shippingCost }}</p>
            <p>帳單平衡: {{ $methods.numberToFixed(data.balanceTotal) }}</p>
            <p>
              訂單金額:
              <span class="text-danger" v-if="loadingForBodies">計算中...</span>
              <span v-else>{{ data.bodiesTotal }}</span>
            </p>
            <p>結單優惠: {{ data.discountAmount ? data.discountAmount : 0 }}</p>
            <p class="badge bg-danger" v-if="data.discountName">
              {{ data.discountName }}
            </p>
            <p>
              購物金折抵: {{ data.walletUseAmount }}
              <span
                class="text-success"
                v-if="data.walletUsed && data.walletUseAmount > 0"
                >(已折)</span
              >
              <span
                class="text-danger"
                v-else-if="!data.walletUsed && data.walletUseAmount > 0"
                >(未折)</span
              >
            </p>
            <p class="mt-2 badge bg-info bg-gradient" v-if="data.showDetail">
              代客直寄
            </p>
            <hr />
            <p class="tw-text-size18 text-danger">
              應付金額:
              <span class="test-danger" v-if="loadingForBodies">計算中...</span>
              <span v-else>{{ $methods.numberToFixed(data.sholdBePay) }}</span>
            </p>
          </template>
        </Column>
        <!-- 操作 -->
        <Column
          field=""
          header="操作"
          style="min-width: 200px"
          v-if="currentPage !== 'finished'"
        >
          <template #body="{ data }">
            <!-- 折抵未折抵 -->
            <div
              class="mb-2"
              v-if="
                data.walletUseAmount &&
                data.is_showWithWallet &&
                currentPage !== 'merged'
              "
            >
              <!-- 未通知付款 -->
              <template v-if="!data.hasPaymentNotification">
                <button
                  class="tw-btn tw-btn-success"
                  v-if="data.walletUsed"
                  @click="setWalletUsed(data)"
                >
                  已折抵
                </button>
                <button
                  class="tw-btn tw-btn-danger"
                  v-else
                  @click="setWalletUsed(data)"
                >
                  未折抵
                </button>
                <!-- 已通知付款 (禁止功能) -->
              </template>
              <template v-else>
                <button
                  class="tw-btn tw-btn-success not-allowed"
                  title="已通知付款不能再更改折抵"
                  disabled
                  v-if="data.walletUsed"
                >
                  已折抵
                </button>
                <button
                  class="tw-btn tw-btn-danger not-allowed"
                  title="已通知付款不能再更改折抵"
                  disabled
                  v-else
                >
                  未折抵
                </button>
              </template>
            </div>

            <!-- 通知已付款/編輯付款資訊 -->
            <div class="mb-2">
              <button
                class="tw-btn tw-btn-secondary"
                v-if="
                  currentPage === 'unpaidAndUnshipped' ||
                  currentPage === 'unpaidAndShipped'
                "
              >
                <span
                  v-if="!data.hasPaymentNotification"
                  @click="showModal('paymentNotification', data, false)"
                  >通知已付款</span
                >
                <span
                  v-else
                  @click="showModal('paymentNotification', data, true)"
                  >編輯付款資訊</span
                >
              </button>
            </div>

            <!-- 編輯出貨資訊 -->
            <div class="mb-2">
              <button
                class="tw-btn tw-btn-secondary"
                v-if="
                  !data.shipped &&
                  (currentPage === 'unpaidAndUnshipped' ||
                    currentPage === 'paidAndUnshipped')
                "
                @click="showModal('editShipmentInfo', data)"
              >
                編輯出貨資訊
              </button>
            </div>
          </template>
        </Column>
        <!---------- 單身 ----------->
        <template #expansion="slotProps">
          <p class="fw-bolder text-danger" v-if="loadingForBodies">
            請稍後~正在為您取得帳單明細!
          </p>
          <ul v-else>
            <li
              class="border-bottom pb-2 row align-items-center"
              v-for="data in slotProps.data.billBodies"
              :key="data.id"
            >
              <!-- 商品圖 -->
              <div class="col-1">
                <Image
                  :imageHash="data.merchandiseImgHash"
                  size="s"
                  v-if="data.merchandiseImgHash"
                  :alt="data.merchandiseImgHash"
                  style="width: 30px; height: 30px"
                ></Image>
                <img
                  src="@/assets/other-images/noImg.png"
                  alt=""
                  v-else
                  style="width: 30px; height: 30px"
                />
              </div>
              <!-- 訂單資訊 -->
              <div class="col-4">
                <p class="fw-bolder text-primary">
                  {{ data.buyerName }}@{{ data.storeName }}
                </p>
                <p>訂單編號: {{ data.merchOrderId }}</p>
                <p>訂單備註: {{ data.merchOrder.note }}</p>
              </div>
              <!-- 購買明細 -->
              <div class="col-4">
                <p>商品: {{ data.merchandiseName }}</p>
                <p>款式: {{ data.style }}</p>
              </div>
              <!-- 狀態 -->
              <div class="col-3">
                <p class="fw-bolder" :class="data.textColorForArrialStatus">
                  到貨狀況: {{ data.arrivalStatus }}
                </p>
                <p>
                  {{ data.quantity }}(數量)X{{ data.price }}(單價)={{
                    $methods.numberToFixed(data.quantity * data.price)
                  }}
                </p>
              </div>
            </li>
          </ul>
        </template>
        <template #empty>
          <p class="text-center fw-bolder text-primary">
            目前尚未有任何資料 0..0
          </p>
        </template>
      </DataTable>
    </div>
  </div>
  <!-- 編輯出貨資訊的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editShipmentInfoModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">編輯出貨資訊</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-------- 選擇出貨方式 --------->
          <div class="row">
            <!-- 方式 -->
            <div class="col p-0 me-2">
              <select
                class="form-select mb-3"
                name=""
                id=""
                v-model="editShipmentInfoData.shippingType"
              >
                <option value="" v-if="storeShippingTypes.loading">
                  <AreaLoading></AreaLoading>
                </option>
                <template v-else>
                  <option value="" disabled>請選擇出貨方式</option>
                  <template
                    v-for="data in storeShippingTypes.data"
                    :key="data.id"
                  >
                    <option
                      v-if="data.customerEnable"
                      :value="data.name"
                      :id="data.name"
                    >
                      {{ data.name }}
                    </option>
                  </template>
                </template>
              </select>
            </div>
            <!-- 金額 -->
            <div class="col p-0">
              <input
                onwheel="this.blur()"
                type="number"
                class="form-control"
                disabled
                :placeholder="`運費: ${this.editShipmentInfoData.shippingCost}`"
              />
            </div>
          </div>
          <!------- 選擇預設出貨資訊 ------>
          <div class="mb-3">
            <select class="form-select" v-model="selectDefaultReceivingInfo">
              <option :value="{}" v-if="defaultReceivingInfos.loading">
                <AreaLoading></AreaLoading>
              </option>
              <template v-else>
                <option :value="{}" disabled>請選擇預設出貨資訊</option>
                <option
                  v-for="(data, i) in defaultReceivingInfos.data"
                  :key="i"
                  :value="data"
                >
                  收件者姓名:{{ data.recipient }}&ensp;&ensp;收件者電話:{{
                    data.phoneNumber
                  }}&ensp;&ensp; 收件者地址:{{ data.City }}{{ data.Area
                  }}{{ data.address }}
                </option>
              </template>
            </select>
          </div>
          <!--------- 收件者姓名 ---------->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="editShipmentName"
              placeholder="收件者姓名(15字)"
              maxlength="15"
              v-model="editShipmentInfoData.name"
            />
            <label for="editShipmentName">收件者姓名(15字)</label>
          </div>
          <!--------- 收件者電話 ---------->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="editShipmentPhone"
              placeholder="收件者電話(15字)"
              maxlength="15"
              v-model="editShipmentInfoData.phone"
            />
            <label for="editShipmentPhone">收件者電話(15字)</label>
          </div>
          <!--------- 收件者地址 ---------->
          <div class="row mb-3">
            <!-- 縣 -->
            <div class="col me-2 p-0">
              <div class="form-floating" v-if="!city.loading">
                <select
                  class="form-select"
                  id="SelectCity"
                  aria-label="選擇縣市"
                  v-model="editShipmentInfoData.city"
                  @click="switchCleanPostalCode"
                >
                  <option value="">不選擇</option>
                  <option
                    :value="data.City"
                    :id="data.City"
                    v-for="data in city.data"
                    :key="data.City"
                  >
                    {{ data.City }}
                  </option>
                </select>
                <label for="SelectCity">選擇縣市</label>
              </div>
              <p class="text-danger" v-else>搜尋中...</p>
            </div>
            <!-- 區域 -->
            <div class="col p-0">
              <div class="form-floating">
                <select
                  class="form-select"
                  id="SelectArea"
                  aria-label="選擇區域"
                  v-model="editShipmentInfoData.postalCode"
                >
                  <option value="">不選擇</option>
                  <option
                    :id="data.Zip5"
                    :value="data.Zip5"
                    v-for="data in area.data"
                    :key="data.Zip5"
                  >
                    {{ data.Area }}
                  </option>
                </select>
                <label for="SelectArea">選擇區域</label>
              </div>
            </div>
          </div>
          <!-- 街道 -->
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="Address"
              placeholder="街道名(30字)"
              maxlength="30"
              v-model="editShipmentInfoData.address"
            />
            <label for="Address">街道名(30字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateShippingInfo"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 通知已付款 / 修改付款資訊 對話框的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="paymentNotificationModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder" v-if="is_created">
            編輯付款資訊
          </h5>
          <h5 class="modal-title tw-border-start fw-bolder" v-else>
            通知已付款
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 匯款方式 -->
          <div class="mb-3">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="paymentType"
                placeholder="匯款方式"
                value="匯款"
                disabled
              />
              <label for="paymentType">匯款方式</label>
            </div>
          </div>
          <!-- 金額 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              min="0"
              id="money"
              v-model="paymentNotificationData.money"
              @input="
                paymentNotificationData.money = $methods.numberToFixed(
                  paymentNotificationData.money
                )
              "
            />
            <label for="money">金額</label>
          </div>
          <!-- 銀行 -->
          <div class="form-floating mb-2">
            <input
              type="text"
              class="form-control"
              id="BankCode"
              placeholder="搜尋銀行"
              v-model="searchBankCode"
            />
            <label for="BankCode">搜尋銀行</label>
          </div>
          <AreaLoading v-if="banksCode.loading"></AreaLoading>
          <div
            class="tw-container border border-secondary mb-3 mx-0"
            style="height: 200px; overflow-y: scroll"
            v-else
          >
            <ul>
              <li
                class="text-start mb-2"
                v-for="data in banksCode.data"
                :key="data.bankCode"
              >
                <input
                  class="me-2"
                  :id="`bank${data.bankCode}`"
                  type="radio"
                  name="bank"
                  :value="data.bankCode"
                  v-model="paymentNotificationData.userPaymentInfo.bankCode"
                />
                <label :for="`bank${data.bankCode}`"
                  >{{ data.bankCode }} {{ data.name }}</label
                >
              </li>
            </ul>
          </div>
          <!-- 帳號末五碼 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="lastFiveDigit"
              v-model="paymentNotificationData.userPaymentInfo.accountNumber"
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="5"
            />
            <label for="lastFiveDigit">帳號末五碼</label>
          </div>
          <!-- 備註 -->
          <div class="form-floating mb-3">
            <textarea
              class="form-control"
              id="note"
              v-model="paymentNotificationData.note"
              maxlength="300"
            />
            <label for="note">備註(300字)</label>
          </div>
          <!-- 付款時間 -->
          <div class="form-floating mb-3">
            <input
              class="form-control"
              type="datetime-local"
              id="paymentTime"
              name="meeting-time"
              v-model="paymentNotificationData.paymentTime"
              placeholder="付款時間"
            />
            <label for="paymentTime" class="mb-2">付款時間</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updatePaymentNotification"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 檢視備註的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="checkNoteModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">檢視備註</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-html="note"></div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';
// api
import { GetCityArea } from '@/methods/API/getCityArea'
import { parseTokenToGetTheParticipant } from '@/methods/API/parseTokenToGetTheParticipant.js'

export default {
  components: {DataTable, Column, ColumnGroup, Row},
  data() {
    return {
      // modal
      editShipmentInfoModal: {},
      paymentNotificationModal: {},
      checkNoteModal: {},
      // data
      serverToken: '',
      storeId: 0,
      participantId: 0,
      slaveId: 0,
      // 目前頁面 
      currentPage: '',
      // dataTable
      scrollHeight: 0,
      dataTabelLoading: false,
      billLists: [],
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // 計算應付金額 & 非同步單身匯出loading
      loadingForBodies: false,
      // 選擇到的物件
      selectItems: [],
      expandedRows: [],
      // 編輯出貨資訊
      editShipmentInfoData: {
        city: '',
        address:'',
        name:'',
        phone:'',
        shipmentInfo:'',
        postalCode: ''
      },
      defaultReceivingInfos: {
        loading: true,
        data: []
      },
      clearPostalCode: false,
      selectDefaultReceivingInfo: {},
      // 通知付款 & 編輯付款方式
      is_created: false, // 判斷是否已建立過
      banksCode: {
        loading: false,
        data: []
      },
      recordBanksCode: [],
      searchBankCode: '',
      paymentNotificationData: {
        userPaymentInfoType: "USERWIRETRANSFERINFO",
        userPaymentInfo: {
          bankCode: '',
          accountNumber: ''
        },
        note: '',
        money: 0,
        paymentTime: ''
      },
      // 運送方式資料
      storeShippingTypes: {
        loading: false,
        data: []
      },
      note: null
    }
  },
  // city: any[]; area: any[]; 來自 GetCityArea
  mixins: [GetCityArea],
  created() {
    this.initialization()
    this.getStoreInfo(['getShippingTypes']) 
    this.getParticipantId()
    this.getCity()
    this.getBankCode()
  },
  mounted() {
    this.createModals(['editShipmentInfoModal', 'paymentNotificationModal', 'checkNoteModal'])
  },
  unmounted() {
    localStorage.removeItem('dt-state-myBill-local')
  },
  watch: {
    '$route.query.status': {
      handler(val, oldVal) {
        // 判斷目前路由位置
        this.currentPage = this.$route.query.status
        if (oldVal && this.currentPage) this.advancedSearch()
        localStorage.removeItem('dt-state-myBill-local')
      },
      immediate: true
    },
    // 選擇縣市 (編輯)
    'editShipmentInfoData.city': {
      handler(val, oldVal) {
        if (this.clearPostalCode) {
          this.clearPostalCode = false
          this.editShipmentInfoData.postalCode = ''
        }
        this.getArea(val)
      },
      deep: true
    },
    // 選擇出貨方式，運費帶入
    'editShipmentInfoData.shippingType' : {
      handler(val, oldVal) {
        this.storeShippingTypes.data.some(item => {
          if (item.customerEnable && item.name === val) {
            if (val === this.editShipmentInfoData.originalShippingType[0]) {
              this.editShipmentInfoData.shippingCost = this.editShipmentInfoData.originalShippingType[1]
            } else this.editShipmentInfoData.shippingCost = item.cost ? item.cost : 0
            return true
          }
        })
      },
      deep: true
    },
    // 出貨方式，電話號碼格式
    'editShipmentInfoData.phone' : {
      handler(val, oldVal) {
        if(val) {
          if ( !/^[0-9]*$/.test(val) ) {
            this.editShipmentInfoData.phone = val.replace(/[^\d]/g, "")
          }
        }
      },
      deep: true
    },
    // 選擇預設出貨資訊
    selectDefaultReceivingInfo(val) {
      if (val.City) {
        this.editShipmentInfoData.city = val.City,
        this.editShipmentInfoData.address = val.address,
        this.editShipmentInfoData.name = val.recipient,
        this.editShipmentInfoData.phone = val.phoneNumber,
        this.editShipmentInfoData.postalCode = val.Zip5
      }
    },
    // 搜尋銀行號
    searchBankCode(val) {
      this.banksCode.data = this.recordBanksCode.filter(item => {
        const code = item.bankCode
        const name = item.name
        return code.indexOf(val) !== -1 || name.indexOf(val) !== -1
      })
    }
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      // 紀錄 dataTabel 高度
      this.scrollHeight = window.innerHeight - 210
    },
    showModal(status, item, mode) {
      if (status === 'editShipmentInfo') {
        // 編輯出貨資訊
        this.selectDefaultReceivingInfo = {}
        this.editShipmentInfoData = {
          id: item.id,
          city: item.recipientCity,
          area: '',
          address: item.receivingAddress,
          name: item.recipient,
          phone: item.recipientPhoneNumber,
          originalShippingType : item.shippingCost ? [item.shippingType,item.shippingCost] : '',
          shippingType: item.shippingType ? item.shippingType : '',
          shippingCost: item.shippingCost ? item.shippingCost : 0,
          postalCode: item.recipientPostalCode ? item.recipientPostalCode : ''
        },
        this.editShipmentInfoModal.show()

      } else if (status === 'paymentNotification') {
        // 通知已付款 & 編輯付款資訊
        this.is_created = mode
        // 通知已付款
        if (!this.is_created) {
          let now = new Date() * 1
          now = this.$methods.moment(now).format("YYYY-MM-DDTHH:mm")
          this.searchBankCode = ''
          this.paymentNotificationData = {
            id: item.id,
            paymentNotificationId: item.paymentNotification.id,
            userPaymentInfoType: "USERWIRETRANSFERINFO",
            userPaymentInfo: {
              bankCode: '',
              accountNumber: ''
            },
            note: '',
            money: 0,
            paymentTime: now
          }
        } else {
          // 編輯付款資訊
          this.searchBankCode = item.paymentNotification.userPaymentInfo.bankCode,
          this.paymentNotificationData = {
            id: item.id,
            paymentNotificationId: item.paymentNotification.id,
            userPaymentInfoType: "USERWIRETRANSFERINFO",
            userPaymentInfo: {
              bankCode: item.paymentNotification.userPaymentInfo.bankCode,
              accountNumber: item.paymentNotification.userPaymentInfo.accountNumber
            },
            note: item.paymentNotification.note,
            money: item.paymentNotification.money,
            paymentTime: this.$methods.moment(item.paymentNotification.paymentTime).format("YYYY-MM-DDTHH:mm")
          }
        }
        
        this.paymentNotificationModal.show()
      } else if (status === 'checkNote') {
        // 檢視備註
        this.note = item ? item.replace(/(\r\n)|(\n)/g,"<br>") : null
        this.checkNoteModal.show()
      }
    },
    // 取得賣場資訊 (抓取運送方式)
    getStoreInfo(methodsArray = []) {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const getDataName = `{${methodsArray.toString()}}`
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: getDataName
        }
      ]
      // 是否要抓取運送方式
      if (methodsArray.includes('getShippingTypes')) {
        this.storeShippingTypes = {
          loading: true,
          data: []
        }
      }
      // 
      // this.prefer = {
      //   loading: true,
      //   D4Row: 20
      // }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            // 是否有抓取運送方式
            if (methodsArray.includes('getShippingTypes')) {
              vm.storeShippingTypes = {
                loading: false,
                data: storeInfo.shippingTypes
              }
            }
            // 偏好設定
            // storeInfo.configuration.forEach(item => {
            //   if (item.category === 'merchOrder') {
            //     if (item.item === 'rowCount') {
            //       item.settingValues.some(row => {
            //         if (row.selected) return vm.prefer.D4Row = parseInt(row.value)
            //       })
            //     }
            //   }
            // })
            // vm.prefer.loading = false
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得目前帳號的 participantId 與 slaveId
    getParticipantId() {
      this.$methods.switchLoading('show')
      this.dataTabelLoading = true
      const result = parseTokenToGetTheParticipant(this.storeId, this.serverToken)
      result.then(res => {
        console.log(res)
        if (res.code === '200') {
          this.participantId = res.data.id
          this.slaveId = res.data.slaves.length > 0 ? res.data.slaves[0].id : 0
          this.advancedSearch()
          this.getReceivingInfos()
        }
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
        this.dataTabelLoading = false
      })
    },
    // 進階搜尋
    advancedSearch() {
      this.dataTabelLoading = true
      let participantIds = [this.participantId]
      if (this.slaveId) participantIds.push(this.slaveId)
      let query = ''
      switch (this.currentPage) {
        // 待付款待出貨
        case "unpaidAndUnshipped":
          query = {"distinct":true,"columns":[{"columnName":"id","siteObjectType":27}],"group":{"groups":[{"column":{"columnName":"buyerId","siteObjectType":27},"operator":{"operator":"=","type":3,"value":participantIds}},{"column":{"columnName":"parentId","siteObjectType":27},"operator":{"operator":true,"type":4}},{"groups":[{"column":{"columnName":"paid","siteObjectType":27},"operator":{"operator":"=","type":1,"value":false}},{"column":{"columnName":"shipped","siteObjectType":27},"operator":{"operator":"=","type":1,"value":false}}],"operator":1}],"operator":1},"siteObjectType":27,"sqlCommandType":1}
          break
        // 已付款待出貨
        case "paidAndUnshipped":
          query = {"distinct":true,"columns":[{"columnName":"id","siteObjectType":27}],"group":{"groups":[{"column":{"columnName":"buyerId","siteObjectType":27},"operator":{"operator":"=","type":3,"value":participantIds}},{"column":{"columnName":"parentId","siteObjectType":27},"operator":{"operator":true,"type":4}},{"groups":[{"column":{"columnName":"paid","siteObjectType":27},"operator":{"operator":"=","type":1,"value":true}},{"column":{"columnName":"shipped","siteObjectType":27},"operator":{"operator":"=","type":1,"value":false}}],"operator":1}],"operator":1},"siteObjectType":27,"sqlCommandType":1}
          break
        // 未付款已出貨
        case "unpaidAndShipped":
          query = {"distinct":true,"columns":[{"columnName":"id","siteObjectType":27}],"group":{"groups":[{"column":{"columnName":"buyerId","siteObjectType":27},"operator":{"operator":"=","type":3,"value":participantIds}},{"column":{"columnName":"parentId","siteObjectType":27},"operator":{"operator":true,"type":4}},{"groups":[{"column":{"columnName":"paid","siteObjectType":27},"operator":{"operator":"=","type":1,"value":false}},{"column":{"columnName":"shipped","siteObjectType":27},"operator":{"operator":"=","type":1,"value":true}}],"operator":1}],"operator":1},"siteObjectType":27,"sqlCommandType":1}
          break
        // 被併單
        case "merged":
          query = {"distinct":true,"columns":[{"columnName":"id","siteObjectType":27}],"group":{"groups":[{"column":{"columnName":"buyerId","siteObjectType":27},"operator":{"operator":"=","type":3,"value":participantIds}},{"column":{"columnName":"hierarchy","siteObjectType":27},"operator":{"operator":"=","type":1,"value":0}},{"column":{"columnName":"parentId","siteObjectType":27},"operator":{"type":4,"operator":false}},{"groups":[{"column":{"columnName":"paid","siteObjectType":27},"operator":{"operator":"=","type":1,"value":false}},{"column":{"columnName":"shipped","siteObjectType":27},"operator":{"operator":"=","type":1,"value":false}}],"operator":2}],"operator":1},"siteObjectType":27,"sqlCommandType":1}
          break
        // 已完成帳單
        case "finished":
          query = {"distinct":true,"columns":[{"columnName":"id","siteObjectType":27}],"group":{"groups":[{"column":{"columnName":"buyerId","siteObjectType":27},"operator":{"operator":"=","type":3,"value":participantIds}},{"column":{"columnName":"parentId","siteObjectType":27},"operator":{"operator":true,"type":4}},{"groups":[{"column":{"columnName":"paid","siteObjectType":27},"operator":{"operator":"=","type":1,"value":true}},{"column":{"columnName":"shipped","siteObjectType":27},"operator":{"operator":"=","type":1,"value":true}}],"operator":1}],"operator":1},"siteObjectType":27,"sqlCommandType":1}
          break
      }
      this.getBillLists(query)
    },
    // 取得帳單
    getBillLists(query) {
      const vm = this
      const getBillListsApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getBillBalances,getPaymentNotification{getUserPaymentInfo},getReceiptPostalCodeDetail,getRecipientPostalCodeDetail}'
        }
      ]
      this.billLists = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillListsApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const billLists = res.data[0].objects
            vm.billListsMap(billLists)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理帳單
    billListsMap(billLists) {
      let headerIds = []
      billLists.forEach(item => {
        headerIds.push(item.id)
        // 原資料
        const object = {
          billBalances: item.billBalances,
          balanceTotal: 0,
          billBodies: null,
          bodiesTotal: null,
          sholdBePay: null,
          buyerId: item.buyerId,
          class: item.class,
          createTime: item.createTime,
          creatorId: item.creatorId,
          discountAmount: item.discountAmount,
          discountName: item.discountName,
          discountType: item.discountType,
          hierarchy: item.hierarchy,
          id: item.id,
          is_showWithWallet: false,
          note: item.note,
          packaged: item.packaged,
          paid: item.paid,
          parentId: item.parentId,
          paymentNotification: item.paymentNotification,
          hasPaymentNotification: item.paymentNotification.paymentTime ? true : false,
          paymentType: item.paymentType,
          // 收據
          receiptAddress: item.receiptAddress,
          receiptBusinessAdminNumber: item.receiptBusinessAdminNumber,
          receiptPhoneNumber: item.receiptPhoneNumber,
          receiptPostalCode: item.receiptPostalCode,
          receiptCity: item.receiptPostalCode ? item.receiptPostalCodeDetail.City : '',
          receiptArea: item.receiptPostalCode ? item.receiptPostalCodeDetail.Area : null,
          receiptTitle: item.receiptTitle,
          // 收件資訊
          receivingAddress: item.receivingAddress,
          recipient: item.recipient,
          recipientPhoneNumber: item.recipientPhoneNumber,
          recipientPostalCode: item.recipientPostalCode,
          recipientCity: item.recipientPostalCode ? item.recipientPostalCodeDetail.City : '',
          recipientArea: item.recipientPostalCode ? item.recipientPostalCodeDetail.Area : null,
          shipped: item.shipped,
          shippedTime: item.shippedTime,
          shippingCost: item.shippingCost,
          shippingType: item.shippingType,
          showDetail: item.showDetail,
          storeId: item.storeId,
          walletUseAmount: item.walletUseAmount,
          walletUsed: item.walletUsed
        }
        // 需特別處理
        // 帳單平衡總計
        object.balanceTotal = 0
        item.billBalances.forEach(item => {
          object.balanceTotal += item.money
        })
        // 是否顯示折抵按鈕
        if (item.paid) object.is_showWithWallet = false
        else {
          // 判斷當下使用者 === 此帳單的participant
          object.is_showWithWallet = this.participantId === item.buyerId
        }
        this.billLists.push(JSON.parse(JSON.stringify(object)))
      })
      this.dataTabelLoading = false
      this.getBillBodies(headerIds)
    },
    // 非同步取得帳單單身
    getBillBodies(headerIds) {
      const vm = this
      const getBillBodiesApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 27,
          ids: headerIds,
          methods: '{getBillBodies{getTopestBillHeader,getMerchOrder{getMerchandise{getMerchandisePictureLinks},getMerchandiseStyles,getBuyer{getUser},getStore}}}' 
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillBodiesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const billList = res.data[0].objects
            vm.setBillBodies(billList)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 將單身塞回去單頭
    setBillBodies(billList) {
      const vm = this
      vm.loadingForBodies = true
      setTimeout(() => {
        vm.billLists.forEach(item => {
          billList.some(hasBodies => {
            if (item.id === hasBodies.id) {
              item.bodiesTotal = 0
              hasBodies.billBodies.forEach(body => {
                // 計算單身總價
                item.bodiesTotal += body.price * body.quantity
                // 取得商品圖片
                body.merchandiseImgHash = null
                body.merchOrder.merchandise.merchandisePictureLinks.some(img => {
                  if (img.front) return body.merchandiseImgHash = img.imageHash
                })
                // 取商品名稱
                body.merchandiseName = body.merchOrder.merchandise.name
                // 組商品款式
                body.style = ''
                body.merchOrder.merchandiseStyles.forEach(style => {
                  body.style += `${style.name} `
                })
                // 訂單來自哪個賣場
                body.storeName = body.merchOrder.store.name
                // 買家
                body.buyerName = body.merchOrder.buyer.user.name
                // 到貨狀況
                body.arrivalStatus = null
                body.textColorForArrialStatus = 'text-danger'
                if (body.merchOrder.allocatedQuantity === 0) {
                  body.arrivalStatus = '未到貨'
                  body.textColorForArrialStatus = 'text-secondary'
                } else if (body.merchOrder.quantity === body.merchOrder.allocatedQuantity) {
                  body.arrivalStatus = '全到貨'
                  body.textColorForArrialStatus = 'text-success'
                } else if (body.quantity === body.merchOrder.quantity && body.merchOrder.allocatedQuantity > 0) {
                  body.arrivalStatus = `到貨: ${body.merchOrder.allocatedQuantity}個`
                  body.textColorForArrialStatus = 'text-warning'
                } else {
                  body.arrivalStatus = '分批結單，無法判斷到貨數量'
                  body.textColorForArrialStatus = 'text-danger'
                }
              })
              // 計算應付金額
              const discountAmount = item.discountAmount ? item.discountAmount : 0
              const shippingCost = item.shippingCost ? item.shippingCost : 0
              let money =  item.walletUseAmount ? item.walletUseAmount : 0
              if (!item.walletUsed) money = 0
              item.sholdBePay = 0
              item.sholdBePay = shippingCost + item.balanceTotal + item.bodiesTotal - discountAmount - money // 有加結單優惠
              item.sholdBePay = this.$methods.numberToFixed(item.sholdBePay)
              item.billBodies = hasBodies.billBodies
              return true
            }
          })
        })
        vm.loadingForBodies = false
      }, 1)
    },
    // 取得銀行代碼
    getBankCode () {
      const vm = this
      const getBankCodeApi = `${process.env.VUE_APP_API}/search/getBankCodeData`
      this.banksCode = {
        loading: true,
        data: []
      }
      this.recordBanksCode = []
      $.ajax({
        type: 'GET',
        async: true,
        url: getBankCodeApi,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            console.log(res.data)
            vm.banksCode = {
              loading: false,
              data: JSON.parse(JSON.stringify(res.data))
            }
            vm.recordBanksCode = JSON.parse(JSON.stringify(res.data))
          } else {
            vm.SweetAlert(res.code, res.message)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      })
    },
    // todo (需帶入被合併帳號?) 取得預設收件資訊
    getReceivingInfos() {
      const vm = this
      const getReceivingInfosApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 13,
          ids: [this.participantId],
          methods: '{getReceivingInfos{getPostalCodeDetail}}' 
        }
      ]
      this.defaultReceivingInfos = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getReceivingInfosApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const receivingInfos = res.data[0].objects[0].receivingInfos
            receivingInfos.forEach(item => {
              console.log(item)
              const object = {
                recipient: item.recipient,
                phoneNumber: item.phoneNumber,
                address: item.address,
                Zip5: item.postalCode,
                City: item.postalCodeDetail ? item.postalCodeDetail.City : "",
                Area: item.postalCodeDetail ? item.postalCodeDetail.Area : "",
              }
              vm.defaultReceivingInfos.data.push(object)
            })
            vm.defaultReceivingInfos.loading = false
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 清除 postalcode
    switchCleanPostalCode() {
      this.clearPostalCode = true
    },
    // 展開全部
    expandAll() {
      this.expandedRows = this.billLists.filter(item => item.id);
    },
    // 收合全部
    collapseAll() {
      this.expandedRows = []
    },
    // 折抵按鈕
    setWalletUsed(bill) {
      this.$methods.switchLoading('show')
      const vm = this
      const setWalletUsedApi = `${process.env.VUE_APP_API}/bill/setWalletUsed`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('headerIds', bill.id)
      data.append('walletUsed', !bill.walletUsed)
      data.append('notify', false)
      $.ajax({
        type: 'PUT',
        async: true,
        url: setWalletUsedApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            // 前端更新資料
            vm.billLists.some(item => {
              if  (item.id === bill.id) {
                if (item.walletUsed) item.sholdBePay += item.walletUseAmount
                else item.sholdBePay -= item.walletUseAmount
                item.walletUsed = !item.walletUsed
                return true
              }
            })
          } else {
            let errorString = '帳單ID: '
            res.errorData[0].errorData.forEach(item => {
              errorString += `${item.id} `
            })
            errorString += res.errorData[0].message
            vm.SweetAlert('other', errorString)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 更新出貨資訊
    updateShippingInfo() {
      this.editShipmentInfoData.area = ''
      this.area.data.some(item => {
        if (this.editShipmentInfoData.postalCode === item.Zip5) {
          this.editShipmentInfoData.area = item.Area
          return true
        }
      })
      if (this.editShipmentInfoData.city && !this.editShipmentInfoData.area ) return this.SweetAlert('other', '請選擇區域')
      this.$methods.switchLoading('show')
      const vm = this
      const updateShippingInfoApi = `${process.env.VUE_APP_API}/bill/updateShippingInfo`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: parseInt(this.editShipmentInfoData.id),
        shippingType: this.editShipmentInfoData.shippingType,
        shippingCost: this.editShipmentInfoData.shippingCost ? parseInt(this.editShipmentInfoData.shippingCost) : 0,
        recipient: this.editShipmentInfoData.name,
        recipientPhoneNumber: this.editShipmentInfoData.phone,
        recipientPostalCode: this.editShipmentInfoData.postalCode,
        receivingAddress: this.editShipmentInfoData.address,
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateShippingInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.billLists.some(item => {
              if (item.id === vm.editShipmentInfoData.id) {
                item.sholdBePay = item.sholdBePay - item.shippingCost + data[0].shippingCost
                item.shippingType = data[0].shippingType
                item.shippingCost = data[0].shippingCost
                item.recipient = data[0].recipient
                item.recipientPhoneNumber = data[0].recipientPhoneNumber
                item.recipientCity = vm.editShipmentInfoData.city
                item.recipientArea = vm.editShipmentInfoData.area
                item.receivingAddress = data[0].receivingAddress
                item.recipientPostalCode = data[0].recipientPostalCode
                return true
              }
            })
            vm.editShipmentInfoModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 通知已付款 / 修改付款資訊
    updatePaymentNotification() {
      if (this.paymentNotificationData.userPaymentInfo.accountNumber.split('').length !== 5) return this.SweetAlert('other', '匯款帳號請輸入五碼數值')
      if (!this.paymentNotificationData.paymentTime) return this.SweetAlert('other', '請輸入付款時間')
      this.$methods.switchLoading('show')
      const vm = this
      const updatePaymentNotificationApi = `${process.env.VUE_APP_API}/paymentNotification/update`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: this.paymentNotificationData.paymentNotificationId,
        userPaymentInfoType: this.paymentNotificationData.userPaymentInfoType,
        userPaymentInfo: {
          bankCode: this.paymentNotificationData.userPaymentInfo.bankCode,
          accountNumber: this.paymentNotificationData.userPaymentInfo.accountNumber
        },
        note: this.paymentNotificationData.note,
        money: this.paymentNotificationData.money,
        paymentTime: new Date(this.paymentNotificationData.paymentTime) * 1
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: updatePaymentNotificationApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.billLists.some(item => {
              if (item.id === vm.paymentNotificationData.id) {
                item.hasPaymentNotification = true
                item.paymentNotification.money = data[0].money
                item.paymentNotification.userPaymentInfo = {
                  bankCode: data[0].userPaymentInfo.bankCode,
                  accountNumber: data[0].userPaymentInfo.accountNumber
                }
                item.paymentNotification.note = data[0].note
                item.paymentNotification.paymentTime = vm.$methods.moment(data[0].paymentTime).format("YYYY-MM-DD HH:mm:ss")
                return true
              }
            })
            vm.paymentNotificationModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.my-bill-title {
  background-color: #ffdd80;
  font-weight: bolder;
  font-size: 18px;
  padding: 15px;
  display: inline-block;
  margin-left: 30px;
  border-radius: 15px 15px 0 0;
}
</style>